import axios from "axios"
import cn from "classnames"
import { useEffect, useRef, useState } from "react"
import "./App.scss"

import { ReactComponent as VectorArrow } from "./assets/VectorArrow.svg"
import { ReactComponent as Dots } from "./assets/dots.svg"
import { ReactComponent as DotsAnimate } from "./assets/dotsAnimate.svg"
import { ReactComponent as DownButton } from "./assets/downButton.svg"
import HeaderLogo from "./assets/logos/HeaderLogo.png"
import ChatAvatar from './assets/sixhands_chat_avatar.png'
import MessageLogo from "./assets/logos/MessageLogo.png"
import MonoLogo from "./assets/logos/MonoLogo.png"
import { useAppDispatch, useAppSelector } from "./state/hooks/redux"
import AppSlice, { IMessage } from "./state/reducers/app.slice"

const timeout = (ms: number) => new Promise(res => setTimeout(res, ms))

const getMessageTime = (ms: number): string => {
	const date = new Date(ms)
	return date.toLocaleTimeString().split(":").slice(0, 2).join(":")
}

let messagesLengts = 0

let userOnBottomMessages = false

function App() {
	const [inBottomMessages, setInBottomMessages] = useState(true)
	const [socketError, setSocketError] = useState(false)
	const [input, setInput] = useState<string>()
	const socketRef = useRef<WebSocket | null>()
	const inputRef = useRef<HTMLInputElement>(null)
	const messagesRef = useRef<HTMLDivElement>(null)

	const dispatch = useAppDispatch()
	const { messages, sessionId } = useAppSelector(state => state.appReducer)

	useEffect(() => {
		// запрос userId
		try {
			getUserId()
		} catch (error) {
			console.error(error)
		}

		const socket = new WebSocket(process.env.REACT_APP_BACKEND_WS!)
		socket.onopen = async () => {
			setSocketError(false)
			socketRef.current = socket
			socket.onmessage = event => {
				const data = event?.data
				if (data && data !== "ping" && typeof data === "string") {
					try {
						const message: Omit<IMessage, "visible"> = JSON.parse(data)
						const messageForStore: IMessage = {
							role: "assistant",
							content: typeof message?.content === "string" ? message?.content : "Not valid message",
							dialogId: message.dialogId,
							visible: "",
							time: Date.now(),
						}
						dispatch(AppSlice.actions.pushMessage(messageForStore))
					} catch (error) {
						console.log("Error on json parse")
					}
				}
			}
		}
		socket.onerror = (err: any) => {
			console.log(err)
			setSocketError(true)
		}
		socket.onclose = () => {
			console.error("socket close")
		}
		return () => socket.close()
	}, [])

	// анимация посимвольного вывода ответа сервера
	useEffect(() => {
		const message = messages.at(-1)
		if (
			messages.length === messagesLengts ||
			!message ||
			message.role !== "assistant" ||
			(message.visible?.length > 1 && message.visible.length !== message.content.length)
		) {
			return
		}
		messagesLengts = messages.length

		const newMessages = JSON.parse(JSON.stringify(messages))
		const timeout = Math.round(2000 / message.content.length) || 10
		for (let i = 0, l = message.content.length; i < l; i++) {
			setTimeout(() => {
				if (!newMessages.length) {
					return console.log("Длина массива 0")
				}
				newMessages[newMessages.length - 1].visible = message.content.substring(0, i + 1)

				dispatch(AppSlice.actions.setAllMessages(newMessages))
			}, i * timeout)
		}
	}, [messages])

	const inputSetFocus = () => {
		inputRef.current?.focus()
	}

	const getUserId = async () => {
		try {
			await axios.get(process.env.REACT_APP_BACKEND_HTTP!).then(res => {
				console.log(res.data)
				dispatch(AppSlice.actions.setSessionId(res.data))
			})
		} catch (error) {}
	}

	const handleChangeInput = (text: string) => {
		setInput(text ?? "")
	}

	const handleEnterPress = (event: any) => {
		if (event?.key === "Enter") {
			sendMessage()
		}
	}

	const isUserAtBottom = () => {
		if (messagesRef.current) {
			return messagesRef.current.scrollHeight - messagesRef.current.scrollTop === messagesRef.current.clientHeight
		} else {
			return false
		}
	}

	// Отслеживание прокрутки страницы для отображения стрелки прокрутки
	useEffect(() => {
		if (messagesRef.current) {
			const handleScroll = () => {
				const inBottom = isUserAtBottom()
				if (userOnBottomMessages != inBottom) {
					userOnBottomMessages = inBottom
					setInBottomMessages(inBottom)
				}
			}
			messagesRef.current.addEventListener("scroll", handleScroll)

			const timeout = setInterval(() => {
				handleScroll()
			}, 300)

			return () => {
				clearInterval(timeout)
				if (messagesRef.current) {
					messagesRef.current.removeEventListener("scroll", handleScroll)
				}
			}
		}
	}, [messagesRef])

	const scrollMessageBlockDown = () => {
		if (messagesRef.current) {
			messagesRef.current.scrollTop = messagesRef.current.scrollHeight
		}
	}

	const isWaitingResponse: boolean =
		(!!messages?.length && messages.at(-1)?.role === "user") || messages.some(mes => mes.content.length !== mes.visible.length) || !sessionId

	const sendMessage = async () => {
		const time = Date.now()
		if (socketRef.current && input && !socketError && !isWaitingResponse) {
			if (!sessionId) return
			dispatch(AppSlice.actions.pushMessage({ role: "user", content: input, visible: input, dialogId: sessionId, time }))

			const messageToServer: Omit<IMessage, "visible"> = { role: "user", content: input, dialogId: sessionId, time }

			socketRef.current?.send(JSON.stringify(messageToServer))

			setInput("")
		}
	}

	return (
		<div className="app">
			<div className="content">
				<div className="header">
					<div className="header_content">
						<img src={HeaderLogo} alt="" />
						Sixhands GPT Alpha
					</div>
				</div>
				<div className="messages" ref={messagesRef} onClick={() => !messages.length && inputSetFocus()}>
					<div className={cn("message_item", { assistant: true })}>
						<div className="message_item_logo">
							<img src={ChatAvatar} alt="" />
						</div>
						<div className="message_item_text">Здравствуйте! Чем я могу вам помочь?</div>
					</div>
					{messages.map((message, index) => (
						<div className={cn("message_item", { [message.role]: true })} key={index}>
							{message.role === "assistant" && (
								<div className="message_item_logo">
									<img src={ChatAvatar} alt="" />
								</div>
							)}
							<div className="message_item_text">
								{message.visible}
								<div className="message_item_time">{getMessageTime(message.time)}</div>
							</div>
						</div>
					))}
					{messages?.at(-1)?.role === "user" && (
						<div className={cn("message_item", { assistant: true })}>
							<div className="message_item_logo">
								<img src={ChatAvatar} alt="" />
							</div>
							<div className="message_item_text">
								<DotsAnimate />
							</div>
						</div>
					)}
				</div>
				<div className="inputBlock">
					{!inBottomMessages && (
						<div className="downScrollButton" onClick={scrollMessageBlockDown}>
							<DownButton />
						</div>
					)}
					<input
						placeholder="Введите сообщение"
						value={input}
						ref={inputRef}
						maxLength={1000}
						autoFocus
						onChange={e => handleChangeInput(e?.target?.value)}
						onKeyPress={handleEnterPress}
					/>
					<div className={cn("submit", { active: !!input && !isWaitingResponse && !socketError })} onClick={sendMessage}>
						<VectorArrow />
					</div>
				</div>
			</div>
		</div>
	)
}

export default App

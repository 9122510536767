import { PayloadAction, createSlice } from "@reduxjs/toolkit"

export interface IMessage {
	dialogId: number
	role: "user" | "assistant"
	content: string
	visible: string
	time: number
}

interface IState {
	messages: IMessage[]
	sessionId: number | null
}
const initialState: IState = {
	messages: [],
	sessionId: null,
}

export const AppSlice = createSlice({
	name: "AppSlice",
	initialState,
	reducers: {
		pushMessage(state, action: PayloadAction<IMessage>) {
			const msg = JSON.parse(JSON.stringify(action.payload))
			state.messages.push(msg)
		},
		setAllMessages(state, action: PayloadAction<IMessage[]>) {
			const msgs = JSON.parse(JSON.stringify(action.payload))
			state.messages = msgs
		},
		setSessionId(state, action: PayloadAction<number>) {
			state.sessionId = action.payload
		},
	},
})

export default AppSlice

import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import App from "./App"
import "./index.css"
import "./normalize.css"
import { setupStore } from "./state/store"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
const store = setupStore()
root.render(
	<Provider store={store}>
		<App />
	</Provider>
)
